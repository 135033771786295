import { useQuery } from "@apollo/react-hooks";
import { Button, Col, Icon, message, Row, Spin } from "antd";
import { Field, FieldArray, Formik } from "formik";
import {
  Form,
  Input,
  InputNumber,
  SubmitButton,
  TimePicker,
} from "formik-antd";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import AddressAutocomplete from "react-google-autocomplete";
import { Link } from "react-router-dom";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import gq_client from "../../apolloClient";
import history from "../../history";
import { ModalMapSelect } from "../ModalMapSelect";
import { CLIENT_BY_PK, CLIENT_FRAGMENT } from "./ClientsQueries";

const CNPJ_MASK = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const clientSchema = Yup.object().shape({
  name: Yup.string().required("Nome é um campo obrigatório"),
  cnpj: Yup.string()
    .min(14, "CNPJ deve conter 14 dígitos")
    .max(14, "CNPJ deve conter 14 dígitos")
    .required("CNPJ é um campo obrigatório"),
  locations: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string().required("Campo obrigatório"),
        address: Yup.string().required("Campo obrigatório"),
        lat: Yup.number().required("Campo obrigatório"),
        lng: Yup.number().required("Campo obrigatório"),
        service_start: Yup.string().required("Campo obrigatório"),
        service_finish: Yup.string().required("Campo obrigatório"),
      })
    )
    .required()
    .min(1, "É preciso inserir pelo menos um endereço"),
});

const handleSave = async (values, { setSubmitting }) => {
  const data = values.locations;
  values.locations = { data: [...data] };

  const { errors } = await gq_client.mutate({
    mutation: gql`
      mutation($values: client_insert_input!) {
        insert_client(objects: [$values]) {
          affected_rows
        }
      }
    `,
    variables: { values },
  });

  !errors
    ? message.success("Cliente salvo com sucesso")
    : message.error("Falha ao salvar cliente");

  history.push("/clients/");
  setSubmitting(false);
};

const handleEdit = async (values, { setSubmitting }) => {
  const data = {
    ...values,
    locations: {
      data: values.locations,
      on_conflict: {
        constraint: "locations_pkey",
        update_columns: [
          "address",
          "lat",
          "lng",
          "service_start",
          "service_finish",
          "description",
        ],
      },
    },
  };

  const { errors } = await gq_client.mutate({
    mutation: gql`
      ${CLIENT_FRAGMENT}
      mutation($values: [client_insert_input!]!) {
        insert_client(
          objects: $values
          on_conflict: {
            constraint: clients_pkey
            update_columns: [name, cnpj]
          }
        ) {
          returning {
            ...Client
          }
        }
      }
    `,
    variables: { values: data },
  });

  !errors
    ? message.success("Cliente editado com sucesso")
    : message.error("Falha ao editar cliente");

  setSubmitting(false);
  history.push("/clients/");
};

const FunctionalClientForm = (props) => {
  const { id } = props.match.params;
  const editMode = Boolean(id);
  const onSubmit = id ? handleEdit : handleSave;
  const { loading, data } = useQuery(CLIENT_BY_PK, { variables: { id } });

  const client = (data && data.client_by_pk) || {
    name: "",
    cnpj: "",
    locations: [
      {
        description: "",
        address: "",
        lat: null,
        lng: null,
        service_start: "08:00",
        service_finish: "18:00",
      },
    ],
  };

  if (loading) return <Spin size="large" />;

  return (
    <>
      <Row style={{ paddingBottom: "20px" }}>
        <Col span={8}>
          <Link to="/clients">
            <Button>
              {" "}
              <Icon type="left" /> Voltar
            </Button>
          </Link>
        </Col>
        <Col span={8}>
          <h1 style={{ textAlign: "center" }}>
            {editMode ? "Editar" : "Adicionar"} Cliente
          </h1>
        </Col>
        <Col span={8} />
      </Row>

      <Formik
        initialValues={client}
        validationSchema={clientSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form {...formItemLayout}>
            <>
              <Form.Item name="name" label="Nome">
                <Input name="name" placeholder="Nome" />
              </Form.Item>

              <Form.Item name="cnpj" label="CNPJ">
                <Field name="cnpj">
                  {({ field, form }) => (
                    <MaskedInput
                      guide={false}
                      mask={CNPJ_MASK}
                      placeholder="00.000.000/0000-00"
                      className="ant-input"
                      {...field}
                      onChange={({ target }) =>
                        form.setFieldValue(
                          field.name,
                          target.value.replace(/\D/g, "")
                        )
                      }
                    />
                  )}
                </Field>
              </Form.Item>

              <hr />

              <h4>Endereços</h4>

              <FieldArray name="locations">
                {({ push, remove }) => (
                  <>
                    <div style={{ marginBottom: 30 }}>
                      <Button
                        type="button"
                        onClick={() =>
                          push({
                            description: "",
                            address: "",
                            lat: null,
                            lng: null,
                            service_start: "08:00",
                            service_finish: "18:00",
                          })
                        }
                      >
                        <Icon type="plus" /> Adicionar endereço
                      </Button>
                    </div>
                    {values.locations.map((location, index) => (
                      <div key={index}>
                        <Form.Item
                          name={`locations[${index}].description`}
                          label="Descrição"
                        >
                          <Input name={`locations[${index}].description`} />
                        </Form.Item>

                        <Form.Item
                          name={`locations[${index}].address`}
                          label="Endereço"
                        >
                          <AddressAutocomplete
                            className="ant-input"
                            type="text"
                            name={`locations[${index}].address`}
                            defaultValue={location.address}
                            placeholder="Endereço"
                            onPlaceSelected={(place) => {
                              const lat = place.geometry.location.lat();
                              const lng = place.geometry.location.lng();
                              setFieldValue(
                                `locations[${index}].address`,
                                place.formatted_address
                              );
                              setFieldValue(`locations[${index}].lat`, lat);
                              setFieldValue(`locations[${index}].lng`, lng);
                            }}
                            types={["address"]}
                            componentRestrictions={{ country: "br" }}
                          />
                          <ModalMapSelect
                            location={location}
                            index={index}
                            onPlaceSelected={({ lat, lng, address }) => {
                              setFieldValue(
                                `locations[${index}].address`,
                                address
                              );
                              setFieldValue(`locations[${index}].lat`, lat);
                              setFieldValue(`locations[${index}].lng`, lng);
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          name={`locations[${index}].lat`}
                          label="Latitude"
                        >
                          <InputNumber
                            style={{ width: "50%" }}
                            name={`locations[${index}].lat`}
                            placeholder="Latitude"
                          />
                        </Form.Item>

                        <Form.Item
                          name={`locations[${index}].longitude`}
                          label="Longitude"
                        >
                          <InputNumber
                            style={{ width: "50%" }}
                            name={`locations[${index}].lng`}
                            placeholder="Longitude"
                          />
                        </Form.Item>

                        <Form.Item
                          name={`locations[${index}].service_start`}
                          label="Início da Operação"
                        >
                          <TimePicker
                            allowClear={false}
                            name={`locations[${index}].service_start`}
                            format="HH:mm"
                            onChange={(_, timeString) =>
                              setFieldValue(
                                `locations.${index}.service_start`,
                                timeString
                              )
                            }
                            value={moment(location.service_start, "HH:mm")}
                            defaultOpenValue={moment(
                              location.service_start,
                              "HH:mm"
                            )}
                          />
                        </Form.Item>

                        <Form.Item
                          name={`locations[${index}].service_finish`}
                          label="Fim da Operação"
                        >
                          <TimePicker
                            allowClear={false}
                            name={`locations[${index}].service_finish`}
                            format="HH:mm"
                            onChange={(_, timeString) =>
                              setFieldValue(
                                `locations.${index}.service_finish`,
                                timeString
                              )
                            }
                            value={moment(location.service_finish, "HH:mm")}
                            defaultOpenValue={moment(
                              location.service_finish,
                              "HH:mm"
                            )}
                          />
                        </Form.Item>

                        <Button type="button" onClick={() => remove(index)}>
                          <Icon type="delete" /> Remover
                        </Button>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>

              <Form.Item name="submit">
                <SubmitButton name="submit" type="primary">
                  Enviar
                </SubmitButton>
              </Form.Item>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FunctionalClientForm;

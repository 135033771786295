import React, { useContext } from "react";
import styled from "styled-components";
import XLSX from "xlsx";
import { Row, Col, Button, Card, Collapse, Icon } from "antd";
import PrintComponents from "react-print-components";
import { ServicesContext } from "../../ServicesContext";
import { flatten } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { CONFIG_QUERY } from "../config/ConfigList";

const Container = styled.div`
  display: flex;
  overflow: auto;
`;

const ColumnContainer = styled.div`
  display: inline-block;
  width: 400px;
  flex-directions: column;
`;

const ServiceList = styled.div`
  padding: 8px;
  transition: backgroud-color 0.2s ease;
  flex-grow: 1;
  min-height: 500px;

  flex-directions: column;
`;

const ServiceContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  width: 100%;
  background-color: ${(props) => (props.isDragging ? "lightgreen" : "white")};
`;

const { Panel } = Collapse;

const Service = ({ service }) => {
  return (
    <ServiceContainer>
      <Card
        title={`${service.client.name || ""} (${
          service.location.description || ""
        })
        `}
        bordered={false}
      >
        <Collapse expandIconPosition="right">
          <Panel key={service.id} header="Detalhes">
            <p>Endereço: {service.location.address}</p>
            <p>Início da operação: {service.service_start}</p>
            <p>Fim da operação: {service.service_finish}</p>
            {service.estimatedArrival && (
              <p>Chegada estimada: {service.estimatedArrival}</p>
            )}
            {service.estimatedDeparture && (
              <p>Saída estimada: {service.estimatedDeparture}</p>
            )}
            <p>Peso: {service.weight} Kg</p>
            <p>Volume: {service.volume} m³</p>
          </Panel>
        </Collapse>
      </Card>
    </ServiceContainer>
  );
};

const PrintableRoute = ({ route }) => {
  const { data = { configuration: [] } } = useQuery(CONFIG_QUERY);

  const config = (data.configuration && data.configuration[0]) || undefined;
  const { fuel_price = 0 } = config || { fuel_price: 0 };

  const routeCost = fuel_price
    ? `R$ ${(route.distance * fuel_price).toFixed(2)}`
    : "Não foi possível calcular o custo da rota";

  const totalWeight = route.services
    .map((s) => Number(s.weight))
    .reduce((a, b) => Number(a) + Number(b), 0);
  const totalVolume = route.services
    .map((s) => Number(s.volume))
    .reduce((a, b) => Number(a) + Number(b), 0);

  return (
    <div className="pagebreak">
      <div className="desk-head">
        <span>
          <b>Numero de itens: </b>
          {route.services.length}
        </span>
        <div>
          <span>
            <b>Motorista: </b>
            {route.driver && route.driver.name}
          </span>
        </div>

        <div>
          <span>
            <b>Veículo: </b>
            {route.vehicle && route.vehicle.plate}
          </span>
        </div>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <span>
              <b>Distancia: </b>
              {route.distance} Km
            </span>
          </Col>
          <Col className="gutter-row" span={12}>
            <span>
              <b>Tempo: </b>
              {route.time} h
            </span>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <span>
              <b>Peso Total: </b>
              {totalWeight} Kg
            </span>
          </Col>
          <Col className="gutter-row" span={12}>
            <span>
              <b>Volume Total: </b>
              {totalVolume} m³
            </span>
          </Col>
        </Row>
        <span>
          <b>Custo da rota: </b>
          {routeCost}
        </span>
      </div>
      <h3>Serviços</h3>
      {route.services.map((service) => (
        <div key={service.id}>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <b>
                {`${service.client.name || ""} (${
                  service.location.description || ""
                })
            `}
              </b>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <span>
                <b>Endereço: </b>
                {service.location.address}
              </span>
            </Col>
            <Col className="gutter-row" span={8}>
              <span>
                <b>Peso: </b>
                {service.weight} Kg
              </span>
            </Col>
            <Col className="gutter-row" span={8}>
              <span>
                <b>Volume: </b>
                {service.volume} m³
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <span>
                <b>Inicio da operação: </b>
                {service.service_start}
              </span>
            </Col>
            <Col className="gutter-row" span={8}>
              <span>
                <b>Fim da operação: </b>
                {service.service_finish}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <span>
                <b>Chegada estimada: </b>
                {service.estimatedArrival}
              </span>
            </Col>
            <Col className="gutter-row" span={8}>
              <span>
                <b>Saida estimada: </b>
                {service.estimatedDeparture}
              </span>
            </Col>
          </Row>
          <hr />
        </div>
      ))}
    </div>
  );
};

const Column = ({ route }) => {
  const { data = { configuration: [] } } = useQuery(CONFIG_QUERY);

  const config = (data.configuration && data.configuration[0]) || undefined;
  const { fuel_price = 0 } = config || { fuel_price: 0 };

  const routeCost = fuel_price
    ? `R$ ${(route.distance * fuel_price).toFixed(2)}`
    : "Não foi possível calcular o custo da rota";

  const totalWeight = route.services
    .map((s) => Number(s.weight))
    .reduce((a, b) => Number(a) + Number(b), 0);
  const totalVolume = route.services
    .map((s) => Number(s.volume))
    .reduce((a, b) => Number(a) + Number(b), 0);

  return (
    <ColumnContainer>
      <div className="desk">
        <div className="desk-head">
          <span>
            <b>Numero de itens: </b>
            {route.services.length}
          </span>
          <div>
            <span>
              <b>Motorista: </b>
              {route.driver && route.driver.name}
            </span>
          </div>

          <div>
            <span>
              <b>Veículo: </b>
              {route.vehicle && route.vehicle.plate}
            </span>
          </div>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <span>
                <b>Distancia: </b>
                {route.distance} Km
              </span>
            </Col>
            <Col className="gutter-row" span={12}>
              <span>
                <b>Chegada prevista: </b>
                {route.time} h
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <span>
                <b>Peso Total: </b>
                {totalWeight} Kg
              </span>
            </Col>
            <Col className="gutter-row" span={12}>
              <span>
                <b>Volume Total: </b>
                {totalVolume} m³
              </span>
            </Col>
          </Row>
          <span>
            <b>Custo da rota: </b>
            {routeCost}
          </span>
        </div>
        <ServiceList>
          {route.services.map((service, index) => (
            <Service key={index} service={service} />
          ))}
        </ServiceList>
      </div>
    </ColumnContainer>
  );
};

const StepFour = () => {
  const { optimizedRoutes = { routes: [] } } = useContext(ServicesContext);
  const { routes } = optimizedRoutes;

  if (!routes.length)
    return (
      <div
        style={{
          background: "#ECECEC",
          padding: "30px",
        }}
      />
    );
  return (
    <div
      style={{
        background: "#ECECEC",
        padding: "30px",
      }}
    >
      <Row style={{ marginBottom: "30px" }}>
        <PrintComponents
          trigger={
            <Button>
              <Icon type="printer" /> Imprimir
            </Button>
          }
        >
          <div>
            <h2>Rotas</h2>
            {optimizedRoutes &&
              optimizedRoutes.routes.map((route, key) => {
                return <PrintableRoute route={route} key={key} />;
              })}
          </div>
        </PrintComponents>

        <Button
          style={{ marginLeft: "20px" }}
          onClick={() => {
            const data = flatten(routes.map(({ services }) => services)).map(
              (service) => ({
                "Nome do Cliente": service.client ? service.client.name : "",
                Descrição: service.location.description || "",
                Peso: service.weight,
                Volume: service.volume,
                Endereço: service.location.address,
                Duração: service.operational_duration,
              })
            );

            const ws = XLSX.utils.json_to_sheet([{}, ...data]);

            /* add to workbook */
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Serviços");

            /* generate an XLSX file */
            XLSX.writeFile(wb, "serviços.xlsx");
          }}
        >
          Exportar Dados
        </Button>
      </Row>
      <Row>
        <Container>
          {routes.map((route, key) => {
            return <Column key={key} route={route} />;
          })}
        </Container>
      </Row>
    </div>
  );
};

export default StepFour;

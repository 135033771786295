import { ApolloLink, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { createClient } from "@infleet/core";

const auth = createClient();

const omitDeep = (obj, key) => {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (val instanceof Date) newObj[i] = val;
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === "object" && val !== null)
        newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
};

const omitDeepArrayWalk = (arr, key) => {
  return arr.map((val) => {
    if (val instanceof Date) return val;
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === "object") return omitDeep(val, key);
    return val;
  });
};

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  return forward(operation).map((data) => {
    return data;
  });
});

export const authLink = setContext(async (_, { headers }) => {
  const authHeaders = await auth.getHttpHeaders();

  return {
    headers: {
      ...authHeaders,
      ...headers,
    },
  };
});

// Create an http link:
export const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTP_SERVER,
});

// Create a WebSocket link:
export const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS_SERVER,
  options: {
    onError: (error) => {
      // error.message has to match what the server returns.
      // Reset the WS connection for it to carry the new JWT.
      wsLink.subscriptionClient.close(false, false);
    },
    lazy: true,
    reconnect: true,
    connectionParams: async () => ({
      headers: { ...(await auth.getHttpHeaders()) },
    }),
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
export const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([
    cleanTypenameLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    link,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export default client;

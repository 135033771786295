import { Icon, Table, Typography } from "antd";
import gql from "graphql-tag";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ServicesContext } from "../../ServicesContext";

const { Column } = Table;
const { Title } = Typography;

// ROMOVE THIS FROM STEP 4
export const CONFIG_QUERY = gql`
  query {
    configuration {
      id
      operational_limit
      operational_duration
      service_finish
      service_start
      fuel_price
    }
  }
`;

const DriversList = () => {
  const { configurations } = useContext(ServicesContext);

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <Title level={2} style={{ textAlign: "center" }}>
          Configurações
        </Title>
      </div>

      <Table pagination={false} dataSource={[configurations]}>
        <Column
          title="Limite Operacional"
          dataIndex="operational_limit"
          key="operational_limit"
          render={(text) => {
            const [hours, minutes, seconds] = text.split(":");
            return `${hours}:${minutes}h`;
          }}
        />
        <Column
          title="Duração de Serviço Padrão"
          dataIndex="operational_duration"
          key="operational_duration"
          render={(text) => {
            const [hours, minutes, seconds] = text.split(":");
            return `${hours}:${minutes}`;
          }}
        />
        <Column
          title="Inicio Padrão de Operação"
          dataIndex="service_start"
          key="service_start"
          render={(text) => {
            const [hours, minutes, seconds] = text.split(":");
            return `${hours}:${minutes}h`;
          }}
        />
        <Column
          title="Final Padrão de Operação"
          dataIndex="service_finish"
          key="service_finish"
          render={(text) => {
            const [hours, minutes, seconds] = text.split(":");
            return `${hours}:${minutes}h`;
          }}
        />
        <Column
          title="Custo do combustível"
          dataIndex="fuel_price"
          key="fuel_price"
        />
        <Column
          title="Ações"
          key="action"
          render={() => (
            <Link to="/config/edit">
              <Icon type="edit" />
            </Link>
          )}
        ></Column>
      </Table>
    </>
  );
};

export default DriversList;

import { GoogleMap, Marker } from "@react-google-maps/api";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Icon,
  message,
  Modal,
  Popconfirm,
  Row,
  Table,
} from "antd";
import NFe from "djf-nfe";
import { Formik } from "formik";
import {
  Form,
  FormItem,
  InputNumber,
  SubmitButton,
  TimePicker,
} from "formik-antd";
import gql from "graphql-tag";
import moment from "moment";
import React, { useContext, useState } from "react";
import AddressAutocomplete from "react-google-autocomplete";
import { createClient, Provider, useQuery } from "urql";
import XLSX from "xlsx";
import { getGeocode } from "../../helpers/helpers";
import { ServicesContext } from "../../ServicesContext";
import { OperationalForm } from "../main/OperationalForm";
import ServiceForm from "./ServiceForm";

const { RangePicker } = DatePicker;

const format = "HH:mm:ss";

// TODO: Get only services for a specific user
export const SERVICE_FRAGMENT = gql`
  fragment Service on service {
    id
    services
    updated_at
  }
`;

export const ALL_SERVICES = gql`
  ${SERVICE_FRAGMENT}
  subscription AllServices {
    service {
      ...Service
    }
  }
`;

const columns = [
  {
    title: "Nome",
    dataIndex: "client.name",
  },
  {
    title: "Endereço",
    dataIndex: "location.address",
  },
  {
    title: "Inicio da Operação",
    dataIndex: "service_start",
  },
  {
    title: "Fim da operação",
    dataIndex: "service_finish",
  },
  {
    title: "Peso",
    dataIndex: "weight",
    render: (text) => `${text} Kg`,
  },
  {
    title: "Volume",
    dataIndex: "volume",
    render: (text) => `${text} m³`,
  },
];

const extractData = async (item, configurations) => {
  const operational_duration = item["F"]
    ? item["F"].padStart(8, 0)
    : configurations.service_duration || "00:20:00";

  return {
    weight: Number(item["C"]),
    volume: Number(item["D"]),
    service_start: configurations.service_start || "08:00:00",
    service_finish: configurations.service_finish || "18:00:00",
    operational_duration,
    client: {
      name: item["A"],
    },
    location: {
      address: item["E"],
      description: item["B"],
      ...(await getGeocode(item["E"])),
    },
  };
};

const StepOne = () => {
  const [modal, setModal] = useState(false);
  const [uploadModal, setuploadModal] = useState(false);
  const {
    services,
    addService,
    deleteService,
    configurations,
    addInvalidService,
    invalidServices,
    deleteInvalidService,
  } = useContext(ServicesContext);

  const loadServices = async (filteredServices) => {
    filteredServices.map(async ({ client, address, weight, volume }) => {
      const s = {
        volume: volume,
        weight: weight,
        service_start: "08:00:00",
        service_finish: "18:00:00",
        operational_duration: "00:20:00",
        client: { name: client },
        location: {
          address: address,
          description: client,
          ...(await getGeocode(address)),
        },
      };
      addService(s);
    });
  };

  const onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length === 1) {
      processFileContent(files[0]);
    }
  };

  const processFileContent = async (file) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });
        const first_sheet_name = workbook.SheetNames[0];
        /* Get worksheet */
        const worksheet = workbook.Sheets[first_sheet_name];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: "A",
          raw: false,
          defval: null,
        });
        const results = data.map((item) => extractData(item, configurations));
        const completed = await Promise.all(results);
        // Remove company header fom file
        const removedHeader = completed.slice(1);

        removedHeader.forEach((item) => {
          if (item.location && item.location.lat && item.location.lng) {
            addService(item);
          } else {
            addInvalidService(item);
          }
        });

        message.success("Arquivo carregado com sucesso!");
        setuploadModal(false);
        // addImportedServices(removedHeader);
      } catch (e) {
        message.error("file type is incorrect!");
        throw Error(e.message);
      }
    };
    fileReader.readAsBinaryString(file);
  };

  return (
    <>
      <Row gutter={16} type="flex" style={{ marginBottom: 30 }}>
        <Col span={4}>
          {/* <Button onClick={loadTest}>Load test</Button> */}
        </Col>
        <Col span={16}>
          <Row gutter={2}>
            <Col span={6}>
              <Button
                style={{ width: "100%" }}
                onClick={() => setuploadModal(true)}
              >
                Importar Excel
              </Button>
            </Col>
            <Col span={6}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => setModal(true)}
              >
                Adicionar serviço
              </Button>
            </Col>
            <Col span={6}>
              <LoadServices loadServices={loadServices} />
            </Col>
            <Col span={6}>
              <LoadInvoices loadServices={loadServices} />
            </Col>
          </Row>
        </Col>
        <Col span={4} />
      </Row>

      {
        <Modal
          visible={uploadModal}
          footer={null}
          width={900}
          onCancel={() => setuploadModal(false)}
        >
          <h1>Importar Clientes</h1>

          <input
            className="file-uploader"
            type="file"
            accept=".xlsx, .xls"
            onChange={onImportExcel}
          />

          <Button type="primary" disabled={true} style={{ float: "right" }}>
            Importar endereços
          </Button>
        </Modal>
      }
      <OperationalForm />

      <Modal
        title="Adicionar Serviço"
        visible={modal}
        width={900}
        onCancel={() => setModal(false)}
        footer={null}
      >
        <ServiceForm />
      </Modal>

      <div style={{ background: "#ECECEC", padding: "30px" }}>
        {!!invalidServices.length && (
          <>
            <h1>Serviços inválidos</h1>
            <Row gutter={16}>
              <Table
                rowClassName="table-row-red"
                rowKey={(record) => record.id}
                columns={[
                  ...columns,
                  {
                    title: "Ações",
                    key: "action",
                    render: (_, record, index) => (
                      <>
                        <EditService
                          service={record}
                          index={index}
                          invalidService
                        />
                        <Divider type="vertical" />
                        <Popconfirm
                          title="Confirmar remoção?"
                          onConfirm={(_) => deleteInvalidService(record.id)}
                          okText="Sim"
                          cancelText="Não"
                        >
                          <Icon type="delete" />
                        </Popconfirm>
                      </>
                    ),
                  },
                ]}
                dataSource={invalidServices}
                pagination={true}
              />
            </Row>
          </>
        )}
        <Row gutter={16}>
          <Table
            rowKey={(record) => record.id}
            columns={[
              ...columns,
              {
                title: "Ações",
                key: "action",
                render: (_, record, index) => (
                  <>
                    <EditService service={record} index={index} />
                    <Divider type="vertical" />
                    <Popconfirm
                      title="Confirmar remoção?"
                      onConfirm={(_) => deleteService(record.id)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <Icon type="delete" />
                    </Popconfirm>
                  </>
                ),
              },
            ]}
            dataSource={services}
            pagination={true}
            locale={{ emptyText: "Nenhum serviço inserido" }}
          />
        </Row>
      </div>
    </>
  );
};

const client = createClient({
  url: "https://ikt-router-server.herokuapp.com/v1/graphql",
});

export default () => (
  <Provider value={client}>
    <StepOne />
  </Provider>
);

const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY").toString();
};

const EditService = ({ service, index, invalidService = false }) => {
  const [modal, setModal] = useState(false);
  const { updateService, addService, deleteInvalidService } = useContext(
    ServicesContext
  );

  const openModal = () => setModal(true);

  const singleClick = ({ latLng }, setFieldValue, oldValues) => {
    const geocoder = new window.google.maps.Geocoder();
    const lat = latLng.lat();
    const lng = latLng.lng();
    let address = "";

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          address = results[0]["formatted_address"];
          setFieldValue("location", {
            lat,
            lng,
            address,
            description: oldValues["description"],
          });
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  };

  const doubleClick = () => {
    return;
  };

  let clickCount = 0;
  let singleClickTimer = "";

  const handleClicks = (event, setFieldValue, oldValues) => {
    clickCount++;
    if (clickCount === 1) {
      singleClickTimer = setTimeout(() => {
        clickCount = 0;
        singleClick(event, setFieldValue, oldValues);
      }, 300);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      doubleClick();
    }
  };

  return (
    <>
      <Icon onClick={openModal} type="edit" />
      <Modal
        visible={modal}
        footer={null}
        width={1200}
        bodyStyle={{ padding: 0 }}
        onCancel={() => setModal(false)}
        destroyOnClose={true}
      >
        <Formik
          initialValues={service}
          onSubmit={(values, { setSubmitting }) => {
            if (!invalidService) {
              updateService(index, values);
            } else {
              deleteInvalidService(service.id);
              addService({ ...values });
            }
            setSubmitting(false);
            setModal(false);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Row gutter={16}>
                <Col span={10}>
                  <div
                    style={{
                      padding: 30,
                      marginTop: "25%",
                    }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <FormItem name="volume" label="Volume">
                          <InputNumber
                            style={{ width: "100%" }}
                            name="volume"
                            placeholder="em m³"
                          />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem name="weight" label="Peso">
                          <InputNumber
                            style={{ width: "100%" }}
                            name="weight"
                            placeholder="em Kg"
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <FormItem
                          name="service_start"
                          label="Início de serviço"
                        >
                          <TimePicker
                            name="service_start"
                            style={{ width: "100%" }}
                            format={format}
                            onChange={(_, timeString) =>
                              setFieldValue("service_start", timeString)
                            }
                            value={moment(values.service_start, format)}
                          />
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem name="service_finish" label="Fim de serviço">
                          <TimePicker
                            name="service_finish"
                            style={{ width: "100%" }}
                            format={format}
                            onChange={(_, timeString) =>
                              setFieldValue("service_finish", timeString)
                            }
                            value={moment(values.service_finish, format)}
                          />
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          name="operational_duration"
                          label="Tempo de Serviço"
                        >
                          <TimePicker
                            name="operational_duration"
                            style={{ width: "100%" }}
                            format={format}
                            onChange={(_, timeString) =>
                              setFieldValue("service_start", timeString)
                            }
                            value={moment(values.operational_duration, format)}
                          />
                        </FormItem>
                      </Col>
                    </Row>

                    <Form.Item
                      name="location.address"
                      label="Endereço"
                      key={values.location.address}
                    >
                      <AddressAutocomplete
                        className="ant-input"
                        type="text"
                        name="location.address"
                        defaultValue={values.location.address}
                        placeholder="Endereço"
                        onPlaceSelected={(place) => {
                          const lat = place.geometry.location.lat();
                          const lng = place.geometry.location.lng();
                          setFieldValue("location", {
                            lat,
                            lng,
                            address: place.formatted_address,
                            description: values.location.description,
                          });
                        }}
                        types={["address"]}
                        componentRestrictions={{ country: "br" }}
                      />
                    </Form.Item>

                    <SubmitButton style={{ float: "right" }}>
                      Salvar alterações
                    </SubmitButton>
                  </div>
                </Col>
                <Col span={14}>
                  <div
                    style={{
                      height: 700,
                      boxShadow: "-8px 8px 20px 8px rgba(0,0,0,0.26)",
                    }}
                  >
                    <GoogleMap
                      id="direction-example"
                      mapContainerStyle={{
                        height: "100%",
                        width: "100%",
                      }}
                      options={{
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                      }}
                      clickableIcons={false}
                      zoom={values.location.lat && values.location.lng ? 16 : 8}
                      center={
                        values.location.lat && values.location.lng
                          ? values.location
                          : { lat: -12.9662976, lng: -38.453248 }
                      }
                      onDblClick={(event) =>
                        handleClicks(event, setFieldValue, values)
                      }
                      onClick={(event) =>
                        handleClicks(event, setFieldValue, values)
                      }
                    >
                      <Marker
                        position={values.location}
                        title={values.location.address}
                      />
                    </GoogleMap>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const LoadServices = ({ loadServices }) => {
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());

  const [{ data = { service: [] }, fetching }] = useQuery({
    query: `{
      service(where: {id: {_eq: "higtop"}}) {
        id
        services
        updated_at
      }
    }`,
  });

  const openModal = () => setModal(true);
  const handleHide = () => setModal(false);

  const onChange = ([startDate, finishDate]) => {
    setStartDate(startDate.toDate());
    setFinishDate(finishDate.toDate());
  };

  const disabledButton = !(data.service.length > 0);

  const filteredServices =
    data.service.length > 0
      ? JSON.parse(data.service[0].services)
          .filter(
            ({ date }) =>
              new Date(date) <= finishDate && new Date(date) >= startDate
          )
          .sort((a, b) => new Date(a.date) - new Date(b.date))
      : [];

  const handleSubmit = () => {
    loadServices(filteredServices);
    setModal(false);
  };

  return (
    <>
      <Button
        disabled={disabledButton}
        style={{
          width: "100%",
        }}
        onClick={openModal}
      >
        Carregar serviços
      </Button>
      <Modal
        width={800}
        title="Filtrar serviços"
        visible={modal}
        onOk={handleSubmit}
        onCancel={handleHide}
      >
        <RangePicker onChange={onChange} />
        <Table
          rowKey={(row) => JSON.stringify(row)}
          dataSource={filteredServices}
          loading={fetching}
        >
          <Table.Column title="Cliente" dataIndex="client" key="client" />
          <Table.Column title="Endereço" dataIndex="address" key="address" />
          <Table.Column title="Peso" dataIndex="weight" key="weight" />
          <Table.Column title="Volume" dataIndex="volume" key="volume" />
          <Table.Column
            title="Data"
            dataIndex="date"
            key="date"
            render={formatDate}
          />
        </Table>
      </Modal>
    </>
  );
};

const LoadInvoices = ({ loadServices }) => {
  const [modal, setModal] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const openModal = () => setModal(true);

  const handleHide = () => {
    setInvoices([]);
    setModal(false);
  };

  const handleSubmit = () => {
    loadServices(invoices);
    setInvoices([]);
    setModal(false);
  };

  const onImportNFe = ({ target }) => {
    const { files } = target;

    for (var i = 0; i < files.length; i++) {
      const fileReader = new FileReader();
      fileReader.onload = async ({ target }) => {
        const { result } = target;
        const nfe = new NFe(result);
        const data = {
          client: nfe.nome(),
          weight: parseFloat(nfe.pesoBruto()),
          volume: 1, // TODO: get real volume from NFe
          address: `${nfe.logradouro()} - ${nfe.municipio()} - ${nfe.uf()}`,
        };
        setInvoices((invoices) => [...invoices, data]);
      };
      fileReader.readAsText(files.item(i), "UTF-8");
    }
  };

  return (
    <>
      <Button
        style={{
          width: "100%",
        }}
        onClick={openModal}
      >
        Carregar NFe
      </Button>
      <Modal
        width={800}
        title="Carregar NFe"
        visible={modal}
        onOk={handleSubmit}
        onCancel={handleHide}
      >
        <input
          className="file-uploader"
          type="file"
          multiple
          accept=".xml"
          onChange={onImportNFe}
        />

        <Table rowKey={(row) => JSON.stringify(row)} dataSource={invoices}>
          <Table.Column title="Cliente" dataIndex="client" key="client" />
          <Table.Column title="Endereço" dataIndex="address" key="address" />
          <Table.Column title="Peso" dataIndex="weight" key="weight" />
          <Table.Column title="Volume" dataIndex="volume" key="volume" />
        </Table>
      </Modal>
    </>
  );
};

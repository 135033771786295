import {
  Button,
  Col,
  Divider,
  Icon,
  message,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import gql from "graphql-tag";
import { groupBy } from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { default as client, default as gq_client } from "../../apolloClient";
import { CLIENT_FRAGMENT } from "./ClientsQueries";
import { getGeocode, uuidv4 } from "../../helpers/helpers";

const { Column } = Table;
const { Title } = Typography;

const filterByClient = (importedData) => {
  const result = groupBy(importedData, "cnpj");
  const keys = Object.keys(result).filter((key) => !(key === "null"));
  const clients = keys.map((key) => {
    let locations = result[key].map((item) => item.location);
    locations = locations.filter((item) => item["lat"] && item["lng"]);

    return {
      name: result[key][0]["name"],
      cnpj: key.replace(/\D+/g, ""),
      locations,
    };
  });
  return clients.filter((item) => !item.length);
};

export const CLIENT_QUERY = gql`
  ${CLIENT_FRAGMENT}
  query AllClients {
    client {
      ...Client
    }
  }
`;

class ClientsList extends Component {
  state = {
    loading: true,
    clients: [],
    uploadModal: false,
    importedData: null,
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const { data, errors } = await gq_client.query({
      query: CLIENT_QUERY,
    });

    if (errors) {
      message.error("Falha ao carregar os dados");
      console.log(errors);
      this.setState({ loading: false });
      return;
    }

    const { client } = data;
    this.setState({ loading: false, clients: client });
  };

  handleDelete = async (id) => {
    const { errors } = await client.mutate({
      mutation: gql`
        mutation($id: uuid!) {
          delete_client(where: { id: { _eq: $id } }) {
            affected_rows
          }
        }
      `,
      variables: { id },
    });

    errors === undefined
      ? message.success("Cliente removido com sucesso")
      : message.warn("Falha ao remover cliente");

    this.loadData();
  };

  openModal = () => this.setState({ uploadModal: true });

  handleCancel = () =>
    this.setState({ uploadModal: false, importedData: null });

  onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.importExcel(
        files[0]
        // Not sure what you want to do with the data, so let's just log it
        // (sku, description, quantity, cost) =>
        //   console.log(sku, description, quantity, cost)
      );
    }
  };

  importExcel = (file, addItem) => {
    const fileReader = new FileReader();
    // const sheetName = "Planilha1";
    fileReader.onload = async (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });
        const first_sheet_name = workbook.SheetNames[0];
        /* Get worksheet */
        const worksheet = workbook.Sheets[first_sheet_name];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: "A",
          raw: true,
          defval: null,
        });
        const results = data.map(async (item) => ({
          name: item["A"],
          cnpj: item["C"],
          location: {
            address: item["F"],
            description: item["B"],
            service_start: item["D"] || "08:00:00",
            service_finish: item["E"] || "18:00:00",
            ...(await getGeocode(item["F"])),
          },
        }));
        const completed = await Promise.all(results);
        const removedHeader = completed.slice(2);
        // console.log(JSON.stringify(removedHeader));
        const filteredByClient = filterByClient(removedHeader);
        this.setState({ importedData: filteredByClient });
        // for (const Sheet in workbook.Sheets) {
        // var XL_row_object =
        // XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        // if (workbook.Sheets.hasOwnProperty(Sheet)) {
        //   const data = XLSX.utils.sheet_to_row_object_array(
        //     workbook.Sheets[Sheet]
        //   );
        //   data.forEach(x => {
        //     addItem(
        //       x.sku,
        //       x.description,
        //       parseInt(x.quantity),
        //       parseFloat(x.cost)
        //     );
        //   });
        // }
        // }
        message.success("upload success!");
      } catch (e) {
        message = message.error("file type is incorrect!");
      }
    };
    fileReader.readAsBinaryString(file);
  };

  savedImportedClients = async () => {
    const { importedData } = this.state;
    importedData.map(async (values, key) => {
      const { errors } = await gq_client.mutate({
        mutation: gql`
          mutation($values: client_insert_input!) {
            insert_client(objects: [$values]) {
              affected_rows
            }
          }
        `,
        variables: { values },
      });

      !errors
        ? message.success(`Cliente ${key + 1} salvo com sucesso`)
        : message.error(`Falha ao salvar cliente ${key + 1}`);
    });

    await this.setState({ importedData: null, uploadModal: false });
  };

  render() {
    let { loading, clients, uploadModal, importedData } = this.state;

    if (loading) return <Spin size="large" />;

    return (
      <>
        <div style={{ marginBottom: 30 }}>
          <Row style={{ paddingBottom: "20px" }}>
            <Col span={8}>
              <Link to="/clients/new">
                <Button type="primary">Adicionar Cliente</Button>
              </Link>
              <Button
                style={{ marginLeft: 20 }}
                onClick={() => this.setState({ uploadModal: true })}
              >
                Importar Excel
              </Button>
            </Col>
            <Col span={8}>
              <Title level={2} style={{ textAlign: "center" }}>
                Clientes
              </Title>
            </Col>
            <Col span={8} />
          </Row>
        </div>

        {
          <Modal
            visible={uploadModal}
            footer={null}
            width={900}
            onCancel={this.handleCancel}
          >
            <h1>Importar Clientes</h1>

            <input
              className="file-uploader"
              type="file"
              accept=".xlsx, .xls"
              onChange={this.onImportExcel}
            />
            <span className="upload-text">Upload files</span>

            <Button
              type="primary"
              disabled={!importedData}
              style={{ float: "right" }}
              onClick={this.savedImportedClients}
            >
              Salvar clientes
            </Button>

            <Table
              rowKey={uuidv4}
              dataSource={importedData || []}
              expandedRowRender={(record) => (
                <div style={{ margin: 0 }}>
                  <h3>Endereços</h3>
                  {record.locations.map((location, key) => (
                    <div key={key}>
                      <p>
                        <b>Endereço: </b>
                        {location.address}
                      </p>
                      <p>
                        <b>Descrição: </b>
                        {location.description}
                      </p>
                      <p>
                        <b>Janela de atendimento: </b>
                        {location.service_start} - {location.service_finish}
                      </p>
                      <hr />
                    </div>
                  ))}
                </div>
              )}
              locale={{ emptyText: "Nenhum cliente importado" }}
            >
              <Column title="Nome" dataIndex="name" key="name" />
              <Column
                title="Descrição"
                dataIndex="description"
                key="description"
              />
              <Column
                title="CNPJ"
                dataIndex="cnpj"
                key="cnpj"
                render={(text) =>
                  text.replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    "$1.$2.$3/$4-$5"
                  )
                }
              />
            </Table>
          </Modal>
        }

        <Table
          rowKey="id"
          dataSource={clients}
          expandedRowRender={(record) => (
            <div style={{ margin: 0 }}>
              <h3>Endereços</h3>
              {record.locations.map((location, key) => (
                <div key={key}>
                  <p>
                    <b>Endereço: </b>
                    {location.address}
                  </p>
                  <p>
                    <b>Descrição: </b>
                    {location.description}
                  </p>
                  <p>
                    <b>Janela de atendimento: </b>
                    {location.service_start} - {location.service_finish}
                  </p>
                  <hr />
                </div>
              ))}
            </div>
          )}
          locale={{ emptyText: "Nenhum cliente cadastrado" }}
        >
          <Column title="Nome" dataIndex="name" key="name" />
          <Column
            title="CNPJ"
            dataIndex="cnpj"
            key="cnpj"
            render={(text) =>
              text.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              )
            }
          />
          <Column
            title="Ações"
            key="action"
            render={(client) => (
              <span>
                <Link to={`/clients/${client.id}`}>
                  <Icon type="edit" />
                </Link>
                <Divider type="vertical" />
                <Popconfirm
                  title="Confirmar remoção?"
                  onConfirm={(_) => this.handleDelete(client.id)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Icon type="delete" />
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      </>
    );
  }
}

export default ClientsList;
